let config = {
  dev: {
    rooturl: "http://localhost:8080",
    version: "0.2.8",
    client_id: "client.id.1",
    client_secret: "client.secret.1",
  },
  prod: {
    rooturl: "",
    version: "0.2.7",
    client_id: "client.id.1",
    client_secret: "client.secret.1",
  },
};
export const APP_MODE = "prod";

export const API_ROOT_URL = `${config[APP_MODE].rooturl}/api`;
export const SOCKET_URL = config[APP_MODE].rooturl;
export const APP_VERSION = config[APP_MODE].version;
export const CLIENT_ID = config[APP_MODE].client_id;
export const CLIENT_SECRET = config[APP_MODE].client_secret;
export const WEBSOCKET_URL = config[APP_MODE].rooturl;
