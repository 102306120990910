import { PlusOutlined } from "@ant-design/icons";
import { Button, message, Tooltip } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { apiRequest } from "../../api";
import apiPaths from "../../api/paths";
import DynamicTable from "../../components/DynamicTable";
import AddUserModal from "./components/AddUserModal";
import Relations from "./components/Relations";

const DEFAULT_COLUMNS = {
  createdDtm: {
    show: false,
    label: "Created Date",
    key: "createdDtm",
  },
  createdBy_info: {
    show: false,
    label: "Created By",
    key: "createdBy_info",
  },
  updatedDtm: {
    show: false,
    label: "Updated Date",
    key: "updatedDtm",
  },
  updatedBy_info: {
    show: false,
    label: "Updated By",
    key: "updatedBy_info",
  },
  email: {
    show: true,
    label: "Email",
    key: "email",
  },
  name: {
    show: true,
    label: "Full Name",
    key: "name",
  },
  status: {
    show: false,
    label: "Status",
    key: "status",
  },
  project_info: {
    show: true,
    label: "Project",
    key: "project_info",
  },
  developer_info: {
    show: true,
    label: "Developer",
    key: "developer_info",
  },
  mobile: {
    show: true,
    label: "Mobile",
    key: "mobile",
  },
  role: {
    show: true,
    label: "Role",
    key: "role",
  },
};

function Users() {
  const [editingKey, setEditingKey] = useState(null);
  const [users, setUsers] = useState({});
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const res = await apiRequest({
      path: apiPaths.users,
    });
    if (!res.error) {
      let usersObj = res.results.reduce((users, user) => {
        users[user.userId + "u"] = user;
        return users;
      }, {});
      setUsers(usersObj);
    } else {
      message.error(res.error_description);
    }
  };

  const COLUMNS = [
    {
      title: "Email",
      dataIndex: "email",
      width: 170,
      filterType: "search",
    },
    {
      title: "Full Name",
      dataIndex: "name",
      width: "150px",
      filterType: "search",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      filterType: "search",
      width: "120px",
    },
    {
      title: "Role",
      dataIndex: "role.role",
      width: "120px",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "120px",
      filterType: "search",
    },
    {
      title: "Manager",
      dataIndex: "manager_info.name",
      width: "120px",
      filterType: "search",
    },
    {
      title: "Created By",
      dataIndex: "createdBy_info.name",
      width: "120px",
      filterType: "search",
    },
    {
      title: "Created Date",
      dataIndex: "createdDtm",
      width: "120px",
      filterType: "date",
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy_info.name",
      width: "120px",
      filterType: "search",
    },
    {
      title: "Updated Date",
      dataIndex: "updatedDtm",
      width: "120px",
      filterType: "date",
    },
  ];

  const handleRowEdit = (idx) => {
    setEditingKey(idx);
    setShowAddUserModal(true);
  };

  const handleAddUser = async (data) => {
    const res = await apiRequest({
      path: apiPaths.users,
      method: "POST",
      data: data,
    });
    if (res.error) {
      message.error(res.error_description);
      return;
    }
    message.success(`User created successfully`);
    setUsers({ [res.userId + "u"]: res, ...users });
    setShowAddUserModal(false);
  };

  const handleUpdateUser = async (data, userId) => {
    const res = await apiRequest({
      path: apiPaths.users + `/${userId}`,
      method: "PUT",
      data: data,
    });
    if (res.error) {
      message.error(res.error_description);
      return;
    }
    message.success(`User updated successfully`);
    let updatedUsers = { ...users };
    updatedUsers[res.userId + "u"] = res;
    setUsers(updatedUsers);
    setEditingKey(null);
    setShowAddUserModal(false);
  };

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: 10,
        }}
      >
        <Tooltip title="Add User">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setShowAddUserModal(true);
            }}
          />
        </Tooltip>
      </div>
      <div style={{ height: "90%", marginTop: 10 }}>
        <DynamicTable
          dataSource={Object.values(users)}
          columns={COLUMNS}
          defaultColumns={DEFAULT_COLUMNS}
          rowKey="userId"
          scroll={{
            // x: 2250,
            y: "90%",
          }}
          expandable={{
            expandedRowRender: (record) => (
              <Relations userId={record.userId} relations={record.relations} />
            ),
          }}
          pagination={false}
          editHandler={(record) => handleRowEdit(record.userId + "u")}
        />
      </div>
      {showAddUserModal ? (
        <AddUserModal
          open={true}
          onAdd={handleAddUser}
          onUpdate={handleUpdateUser}
          onCancel={() => {
            setShowAddUserModal(false);
          }}
          data={editingKey ? users[editingKey] : false}
        />
      ) : null}
    </div>
  );
}

export default Users;
